import { UserOutlined } from '@ant-design/icons'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {
  DashBoardIcon,
  AlertsIcon,
  MessagesIcon,
  AuditsIcon,
  PoliciesIcon,
  SaasAppsIcon,
  UsersGroupsIcon,
  SettingsIcon,
  GroupAdvanced,
  GuacamoleIcon,
} from 'utils/navMenuIcons'

const monitoringNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'sidenav.dashboard',
    icon: DashBoardIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: false,
    isCompanyLimitedView: false,
    submenu: [
      {
        key: 'security',
        path: `${APP_PREFIX_PATH}/dashboard-security`,
        title: 'sidenav.dashboard.security',
        // icon: LineChartOutlined,
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: false,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'performance',
        path: `${APP_PREFIX_PATH}/dashboard-performance`,
        title: 'sidenav.dashboard.performance',
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: true,
        submenu: [],
        isCompanyLimitedView: false,
      },
    ],
  },
  {
    key: 'alert',
    path: `${APP_PREFIX_PATH}/alerts`,
    title: 'sidenav.alerts',
    icon: AlertsIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: false,
    submenu: [],
    isCompanyLimitedView: false,
  },
  {
    key: 'messages',
    path: `${APP_PREFIX_PATH}/messages`,
    title: 'sidenav.messages',
    icon: MessagesIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: true,
  },
  {
    key: 'audit',
    path: `${APP_PREFIX_PATH}/audit-new`,
    title: 'sidenav.audit',
    icon: AuditsIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [
      {
        key: 'change-logs',
        path: `${APP_PREFIX_PATH}/audit-new/change-logs`,
        title: 'sidenav.audit.new.change.logs',
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: false,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'malware-logs',
        path: `${APP_PREFIX_PATH}/audit-new/malware-logs`,
        title: 'sidenav.audit.new.malware.logs',
        icon: null,
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: false,
        submenu: [],
        isCompanyLimitedView: true,
      },
      {
        key: 'performance-logs',
        path: `${APP_PREFIX_PATH}/audit-new/performance-logs`,
        icon: null,
        title: 'sidenav.audit.new.performance.logs',
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: false,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'rpa-logs',
        path: `${APP_PREFIX_PATH}/audit-new/rpa-logs`,
        icon: null,
        title: 'sidenav.audit.new.rpa.logs',
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: false,
        submenu: [],
        isCompanyLimitedView: true,
      },
      {
        key: 'navigation-history-report',
        path: `${APP_PREFIX_PATH}/audit-new/navigation-history-report`,
        icon: null,
        title: 'sidenav.audit.new.navigation.history.report',
        breadcrumb: false,
        isRestricted: true,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'navigation-history',
        path: `${APP_PREFIX_PATH}/audit-new/navigation-history`,
        icon: null,
        title: 'sidenav.audit.new.navigation.history',
        breadcrumb: false,
        isRestricted: false,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'ai-tool-usage',
        path: `${APP_PREFIX_PATH}/audit-new/ai-tool-usage`,
        icon: null,
        title: 'sidenav.ai.tools.usage',
        breadcrumb: false,
        isRestricted: false,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'dashboard-repots',
        path: `${APP_PREFIX_PATH}/audit-new/reports`,
        icon: null,
        title: 'sidenav.reports',
        breadcrumb: false,
        isRestricted: false,
        submenu: [],
        isCompanyLimitedView: true,
      },
    ],
    isCompanyLimitedView: false,
  },
]

const managementNavTree = [
  {
    key: 'policies',
    path: `${APP_PREFIX_PATH}/policies`,
    title: 'sidenav.policies',
    icon: PoliciesIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: false,
  },
  {
    key: 'policies-advanced-rule',
    path: `${APP_PREFIX_PATH}/advanced-policies`,
    title: 'sidenav.advanced.policies',
    icon: PoliciesIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: false,
  },
  {
    key: 'advanced-policies',
    path: `${APP_PREFIX_PATH}/advanced-policies`,
    title: 'sidenav.advanced.policies',
    icon: PoliciesIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: true,
  },
  {
    key: 'applications',
    path: `${APP_PREFIX_PATH}/applications`,
    title: 'sidenav.saas.applications',
    icon: SaasAppsIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: true,
  },
  {
    key: 'users-and-groups',
    path: `${APP_PREFIX_PATH}/users-and-groups`,
    title: 'sidenav.users.and.groups',
    icon: UsersGroupsIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: false,
  },
  {
    key: 'guacamole',
    path: `${APP_PREFIX_PATH}/guacamole`,
    title: 'sidenav.guacamole',
    icon: GuacamoleIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: false,
  },
  {
    key: 'groups-advanced',
    path: `${APP_PREFIX_PATH}/groups-advanced`,
    title: 'sidenav.groups',
    icon: GroupAdvanced,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: false,
  },
  {
    key: 'owner-settings',
    path: `${APP_PREFIX_PATH}/owner-settings`,
    title: 'sidenav.owner.settings',
    icon: UserOutlined,
    breadcrumb: false,
    isRestricted: true,
    isViewerRestricted: true,
    submenu: [],
    isCompanyLimitedView: true,
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'sidenav.settings',
    icon: SettingsIcon,
    breadcrumb: false,
    isRestricted: false,
    isViewerRestricted: true,
    submenu: [
      {
        key: 'integrations',
        path: `${APP_PREFIX_PATH}/settings-integrations`,
        title: 'sidenav.integrations',
        icon: null,
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: true,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'global-thresholds',
        path: `${APP_PREFIX_PATH}/settings-global-thresholds`,
        title: 'sidenav.global.thresholds',
        icon: null,
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: true,
        submenu: [],
        isCompanyLimitedView: true,
      },
      {
        key: 'malware-protection',
        path: `${APP_PREFIX_PATH}/malware-protection`,
        title: 'sidenav.malware.protection',
        icon: null,
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: true,
        submenu: [],
        isCompanyLimitedView: true,
      },
      {
        key: 'addition-setup',
        path: `${APP_PREFIX_PATH}/addition-setup`,
        title: 'sidenav.additional.setup',
        icon: null,
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: true,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'browser-setup',
        path: `${APP_PREFIX_PATH}/settings-browser-setup`,
        title: 'sidenav.browser.setup',
        icon: null,
        breadcrumb: false,
        isRestricted: false,
        isViewerRestricted: true,
        submenu: [],
        isCompanyLimitedView: false,
      },
      {
        key: 'malicious-processes',
        path: `${APP_PREFIX_PATH}/malicious-processes`,
        title: 'sidenav.malicious.processes',
        icon: null,
        breadcrumb: false,
        isRestricted: true,
        isViewerRestricted: true,
        submenu: [],
        isCompanyLimitedView: true,
      },
    ],
    isCompanyLimitedView: false,
  },
]

const groupedNavConfig = [monitoringNavTree, managementNavTree]

export default groupedNavConfig
